import Macy from 'macy';

export default class Masonry {
  /**
   * Masonry class
   * @param settings {Object}
   * @param useDefaults {Number}
   */
  constructor(settings, useDefaults = 0) {
    this.data = {
      settings,
      useDefaults,
      instance: null,
      defaultSettingsTwoColumns: {
        trueOrder: true,
        mobileFirst: true,
        columns: 1,
        margin: {
          x: 24,
          y: 0,
        },
        breakAt: {
          768: {
            margin: {
              x: 24,
              y: 0,
            },
            columns: 2,
          },
          992: {
            margin: {
              x: 64,
              y: 0,
            },
            columns: 2,
          },
          1200: {
            margin: {
              x: 80,
              y: 0,
            },
            columns: 2,
          },
          1400: {
            margin: {
              x: 100,
              y: 0,
            },
            columns: 2,
          },
        },
      },
      defaultSettingsThreeColumns: {
        trueOrder: true,
        mobileFirst: true,
        columns: 2,
        margin: {
          x: 24,
          y: 0,
        },
        breakAt: {
          992: {
            margin: {
              x: 24,
              y: 0,
            },
            columns: 3,
          },
          1200: {
            margin: {
              x: 36,
              y: 0,
            },
            columns: 3,
          },
          1400: {
            margin: {
              x: 36,
              y: 0,
            },
            columns: 3,
          },
        },
      },
    };

    this.setDefaultOptions();
    this.mount();
  }

  mount() {
    this.data.instance = Macy(
      this.data.settings,
    );
  }

  unmount() {
    this.data.instance.remove();
  }

  setDefaultOptions() {
    switch (this.data.useDefaults) {
      case 2: {
        this.data.settings = { ...this.data.defaultSettingsTwoColumns, ...this.data.settings };
        break;
      }
      case 3: {
        this.data.settings = { ...this.data.defaultSettingsThreeColumns, ...this.data.settings };
        break;
      }
      default:
        break;
    }
  }

  getMacyInstance() {
    return this.data.instance;
  }
}
