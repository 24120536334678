import './styles/index.scss';

// vendor
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import ripplet from 'ripplet.js';

// components
import CookiesNotice from '@/js/components/cookies';
import Cursor from '@/js/components/cursor';

// load views
import './js/views';

const dom = {
  cookieNotice: document.getElementById('cookies-notice'),
  postForms: document.querySelectorAll('form[method="post"]'),
  rippleHover: document.querySelectorAll('.js-ripple-hover'),
  cursor: document.querySelector('.js-cursor'),
};

const data = {
  spamCheck: {
    name: 'spamcheck',
    value: 'pu9gqlfdkao6dltp3m7h',
  },
};

const components = {
  cookieNotice: null,
  cursor: null,
};

// cookies notice
if (dom.cookieNotice) {
  components.cookieNotice = new CookiesNotice(dom.cookieNotice);
}

// Initialize custom cursor
if (dom.cursor) {
  components.cursor = new Cursor(dom.cursor);
}

/**
 * on form submit
 * @param e {Event}
 */
function onFormSubmit(e) {
  const form = e.target;
  const existingInput = form.querySelector('input[name="spamcheck"]');

  if (existingInput) {
    // spam check already in place
    form.removeEventListener('submit', onFormSubmit);
  } else {
    // add spam check
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = data.spamCheck.name;
    input.value = data.spamCheck.value;
    form.appendChild(input);
  }
}

// on post form submit
dom.postForms.forEach((form) => {
  form.addEventListener('submit', onFormSubmit);
});

// rippleHover
dom.rippleHover.forEach((el) => {
  // hover
  el.addEventListener('pointerenter', (e) => {
    ripplet(e, {
      spreadingDuration: '.25s',
      clearingDuration: '.6s',
      color: 'rgb(255, 255, 255)',
      clearing: false,
      opacity: 0.15,
    });
  });

  // clear
  el.addEventListener('pointerleave', () => {
    ripplet.clear(el);
  });
});

// load all icons
(function loadIcons(r) {
  r.keys().forEach(r);
}(require.context('./img/icons', true, /\.svg$/)));

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
