import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import * as Offcanvas from 'bootstrap/js/dist/offcanvas';

// register gsap plugins
gsap.registerPlugin(ScrollTrigger);

const dom = {
  siteNav: document.querySelector('.l-site-nav'),
  offcanvas: document.getElementById('site-nav-offcanvas'),
};

const data = {
  bp: 992,
  siteNavOffcanvasMq: '(min-width: 992px)',
  siteNavStateClass: 'mbm-difference',
  offcanvasOpenClass: 'offcanvas-open',
  timeline: null,
};

function onEnter() {
  dom.siteNav.classList.remove(data.siteNavStateClass);
}

function onLeave() {
  dom.siteNav.classList.add(data.siteNavStateClass);
}

function animate() {
  const htmlState = document.documentElement.dataset.state;
  if (htmlState && htmlState !== 'STATE_COMPLETE') return;

  data.timeline = gsap
    .timeline({
      scrollTrigger: {
        trigger: dom.siteNav,
        end: 'bottom top',
        onEnter,
        onEnterBack: onEnter,
        onLeave,
        onLeaveBack: onLeave,
        onUpdate: () => {
          if (dom.offcanvas.classList.contains('show')) onEnter();
        },
      },
    });
}

// close site nav offcanvas if breakpoint condition is met
if (dom.offcanvas) {
  document.documentElement.addEventListener('state_change', () => {
    if (data.timeline) {
      data.timeline.kill();
    }
    animate();
  });

  dom.offcanvas.addEventListener('show.bs.offcanvas', () => {
    document.body.classList.add(data.offcanvasOpenClass);
  });

  dom.offcanvas.addEventListener('hidden.bs.offcanvas', () => {
    document.body.classList.remove(data.offcanvasOpenClass);
  });

  window.matchMedia(data.siteNavOffcanvasMq).addEventListener('change', (e) => {
    if (e.matches) {
      const offcanvasInstance = Offcanvas.getInstance(dom.offcanvas);

      if (offcanvasInstance) {
        offcanvasInstance.hide();
      }
    }
  });
}

function init() {
  if (!dom.siteNav) return;
  ScrollTrigger.matchMedia({
    // revert all gsap changes
    [`(max-width: ${data.bp}px)`]: () => animate(),
    // apply gsap
    [`(min-width: ${data.bp}px)`]: () => onEnter(),
  });
}

init();
