import axios from 'axios';
import * as Modal from 'bootstrap/js/dist/modal';

import { root } from '@/js/utils/paths';
import { lang } from '@/js/utils/locales';

export default class CookiesNotice {
  constructor(cookieNoticeElement) {
    this.dom = {
      cookieNotice: cookieNoticeElement,
      form: cookieNoticeElement.querySelector('form[name="CookieNotice"]'),
      modal: document.getElementById('cookie-customizer-modal'),
      triggers: [...document.querySelectorAll('.js-cookie-trigger')],
    };

    this.data = {
      url: `${root}${lang}/privacy`,
      formData: null,
      loadingClass: 'is-loading',
      htmlDisableScrollClass: 'overflow-hidden',
      acceptAllCookies: 'accept-all-cookies',
    };

    this.events = {
      setCookies: this.setCookies.bind(this),
      onModalHidden: this.onModalHidden.bind(this),
    };

    this.components = {
      modal: null,
    };

    this.mount();
  }

  mount() {
    this.dom.triggers.forEach((trigger) => {
      trigger.addEventListener('click', this.events.setCookies);
    });
  }

  // remove from dom
  destroy() {
    this.dom.cookieNotice.remove();
  }

  // set to loading state
  setLoadingState() {
    // hide the notice
    this.dom.cookieNotice.classList.add(this.data.loadingClass);
    // allow scroll
    document.documentElement.classList.remove(this.data.htmlDisableScrollClass);
  }

  // set to default state
  resetState() {
    // hide the notice
    this.dom.cookieNotice.classList.remove(this.data.loadingClass);
    // disallow scroll
    document.documentElement.classList.add(this.data.htmlDisableScrollClass);
  }

  /**
   * Event is fired when the modal has finished being hidden from the user
   * (will wait for CSS transitions to complete).
   */
  onModalHidden() {
    this.dom.modal.removeEventListener('hidden.bs.modal', this.events.onModalHidden);
    this.components.modal.dispose();
    this.destroy();
  }

  /**
   * set cookie
   * @param e {Event}
   */
  setCookies(e) {
    e.preventDefault();

    // allow interaction
    this.setLoadingState();
    const { target } = e;

    // set form data
    this.data.formData = new FormData(this.dom.form);
    this.data.formData.append('action', target.dataset.name);

    axios.post(this.data.url, this.data.formData)
      .then((response) => {
        if (response) {
          this.components.modal = Modal.getInstance(this.dom.modal);

          response.data.forEach((scriptContent) => {
            if (scriptContent.URL) {
              const script = document.createElement('script');
              script.type = 'text/javascript';
              script.src = scriptContent.URL;
              document.body.appendChild(script);
            }

            // create script for each node
            if (scriptContent.EmbedCode) {
              const script = document.createElement('script');
              script.type = 'text/javascript';
              script.text = scriptContent.EmbedCode;
              document.body.appendChild(script);
            }

            // execute script
            if (scriptContent.Callback) {
              window.Function(scriptContent.Callback)();
            }
          });

          if (this.components.modal) {
            // hide model, then remove from dom
            this.dom.modal.addEventListener('hidden.bs.modal', this.events.onModalHidden);
            this.components.modal.hide();

            // remove overflow set by bootstrap modal
            // document.body.classList.remove('modal-open');
            // document.body.style.overflow = 'visible';
          } else {
            // all done, remove from dom
            this.destroy();
          }
        } else {
          // something went wrong, reset state
          this.resetState();
        }
      })
      .catch((error) => {
        console.error(error);
        this.destroy();
      });
  }
}
