// vendor
import baguetteBox from 'baguettebox.js';

// components
import Masonry from '@/js/components/masonry';
import CaseItem from '@/js/components/caseItem';

class Project {
  constructor() {
    this.dom = {
      projectFilters: document.querySelectorAll('button[data-sector]'),
      projectRow: document.querySelector('.row-projects'),
      projectNodes: document.querySelectorAll('.row-projects [data-sector]'),
      cases: document.querySelectorAll('.c-card-case'),
      masonry: document.getElementById('masonry-projects'),
    };

    this.data = {
      projectFilterSector: '',
      activeButtonClass: 'btn-primary-gradient',
      inactiveButtonClass: 'btn-outline-primary-gradient',
    };

    this.components = {
      masonry: null,
      caseItems: [],
    };

    this.events = {
      onProjectFilterClick: this.onProjectFilterClick.bind(this),
    };

    this.mount();
  }

  mount() {
    this.dom.cases.forEach((el) => this.components.caseItems.push(new CaseItem(el)));

    this.dom.projectFilters.forEach((btn) => {
      btn.addEventListener('click', this.events.onProjectFilterClick);
    });

    // init baguetteBox
    baguetteBox.run('.js-gallery');

    // init masonry
    if (this.dom.masonry) {
      this.components.masonry = new Masonry({ container: this.dom.masonry }, 3);
    }
  }

  /**
   * Filter button has been clicked
   * @param e {MouseEvent}
   */
  onProjectFilterClick(e) {
    e.preventDefault();
    const { sector } = e.currentTarget.dataset;

    // check if state has changed
    if (sector !== this.data.projectFilterSector) {
      this.setProjectFilterSector(sector);
      this.setFilterButtonState(e.currentTarget);
    }
  }

  /**
   * Sector had changed
   * @param sector {String}
   */
  setProjectFilterSector(sector) {
    // set active state
    this.data.projectFilterSector = sector;
    this.dom.projectRow.innerHTML = '';

    if (this.data.projectFilterSector === 'all') {
      // show all
      this.dom.projectRow.append(...this.dom.projectNodes);
    } else {
      // show filtered
      const results = [...this.dom.projectNodes].filter((el) => el.dataset.sector === this.data.projectFilterSector);
      this.dom.projectRow.append(...results);
    }

    // recalculate
    const macy = this.components.masonry.getMacyInstance();
    macy.recalculate(true);
  }

  /**
   * Set filter button state
   * @param button {Element}
   */
  setFilterButtonState(button) {
    const activeButtons = document.querySelectorAll('.btn-primary-gradient[data-sector]');

    // remove active button state
    activeButtons.forEach((btn) => {
      btn.classList.remove(this.data.activeButtonClass);
      btn.classList.add(this.data.inactiveButtonClass);
    });

    // set active button state
    button.classList.remove(this.data.inactiveButtonClass);
    button.classList.add(this.data.activeButtonClass);
  }
}

if (document.getElementById('page-projects')) {
  // eslint-disable-next-line no-new
  new Project();
}
