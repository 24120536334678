import fitty from 'fitty';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// components
import ParallaxTwoColumns from '@/js/components/animations/parallax_two_columns';

// register gsap plugins
gsap.registerPlugin(ScrollTrigger);

export default class Cases {
  constructor(section) {
    this.dom = {
      section,
      title: section.querySelector('.js-case-title'),
      rowCases: section.querySelector('.row-cases'),
    };

    this.data = {
      rowCasesBp: 768,
      titleFitTextSizes: {
        max: 120,
      },
    };

    this.components = {
      rowParallax: null,
    };

    this.events = {
      fit: this.titleAnimation.bind(this),
    };

    this.mount();
  }

  mount() {
    if (this.dom.title) {
      this.titleFitText();
      // fired when the element has been fitted to the parent container
      this.dom.title.addEventListener('fit', this.events.fit);
    }

    if (this.dom.rowCases) {
      this.casesParallax();
    }
  }

  // fit text to container
  titleFitText() {
    fitty(this.dom.title, {
      maxSize: this.data.titleFitTextSizes.max,
    });
  }

  // title animation
  titleAnimation() {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: this.dom.title,
          scrub: true,
        },
      })
      .fromTo(this.dom.title.firstElementChild,
        { x: '-25%', ease: 'none' },
        { x: '25%', ease: 'none' },
        0)
      .fromTo(this.dom.title.lastElementChild,
        { x: '25%', ease: 'none' },
        { x: '-25%', ease: 'none' },
        0);
  }

  // parallax cases
  casesParallax() {
    this.components.rowParallax = new ParallaxTwoColumns(
      this.dom.rowCases, {
        bp: this.data.rowCasesBp,
      },
    );
  }
}
