export default class Noise {
  constructor(canvas) {
    this.dom = {
      canvas,
    };

    this.data = {
      ctx: this.dom.canvas.getContext('2d'),
      interval: null,
      intervalDelay: 200, // in ms
    };

    this.events = {
      resize: this.resize.bind(this),
    };

    this.mount();
  }

  mount() {
    window.addEventListener('resize', this.events.resize);
    this.resize();
    this.loop();
  }

  unmount() {
    window.removeEventListener('resize', this.events.resize);
  }

  resize() {
    this.dom.canvas.width = window.innerWidth;
    this.dom.canvas.height = window.innerHeight;
  }

  noise() {
    const w = this.data.ctx.canvas.width;
    const h = this.data.ctx.canvas.height;
    const imgData = this.data.ctx.createImageData(w, h);
    const buffer32 = new Uint32Array(imgData.data.buffer);
    const len = buffer32.length;

    for (let i = 0; i < len; i += 1) {
      // eslint-disable-next-line no-bitwise
      buffer32[i] = ((255 * Math.random()) | 0) << 24;
    }

    this.data.ctx.putImageData(imgData, 0, 0);
  }

  loop() {
    // set interval
    this.data.interval = setInterval(() => {
      // clear interval
      clearInterval(this.data.interval);
      // regenerate noise
      this.noise();
      // recurse
      requestAnimationFrame(this.loop.bind(this));
    }, this.data.intervalDelay);
  }
}
