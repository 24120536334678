/**
 * Check if hostname is local or external
 * @param host {string}
 */
export function usesSubFolder(host) {
  if (host.includes('.')) return false;

  if (host.includes(':')) return false;

  return true;
}

/**
 * Get root URL
 * @returns {string}
 */
export function getRoot() {
  const {
    protocol, pathname, host,
  } = window.location;
  let tempRoot = `${protocol}//${host}/`;

  if (usesSubFolder(host)) {
    const segments = pathname.split('/');
    tempRoot += `${segments[1]}/`;
  }

  return tempRoot;
}

// alias for getRoot()
export const root = getRoot();

export default {
  root,
  usesSubFolder,
};
