import { gsap } from 'gsap';
import 'splitting/dist/splitting.css';
import Splitting from 'splitting';

export default class Intro {
  constructor(section) {
    this.dom = {
      section,
      title: section.querySelector('.js-title'),
      buttons: section.querySelectorAll('.btn'),
      slides: section.querySelectorAll('.swiper-slide'),
      scrollIndicator: section.querySelector('.scroll-indicator'),
      nav: document.querySelector('.l-site-nav'),
    };

    this.states = {
      loading: 'STATE_LOADING',
      animating: 'STATE_ANIMATING',
      complete: 'STATE_COMPLETE',
    };

    this.data = {
      titleParts: [],
      timeline: null,
      state: this.states.loading,
      sessionStorage: {
        key: 'vermeulenIntro',
        value: this.states.complete,
      },
    };

    this.events = {
      onAnimationComplete: this.onAnimationComplete.bind(this),
      onLoad: this.mount.bind(this),
      done: new Event('done'),
    };

    this.customEvents = {
      stateChange: new Event('state_change'),
    };

    // check if session exists
    if (this.getSessionStorage() === this.data.sessionStorage.value) {
      // set complete state
      this.setState(this.states.complete);

      // dispatch custom event
      document.body.dispatchEvent(this.events.done);
    } else {
      // animate
      window.addEventListener('load', this.events.onLoad);
    }
  }

  mount() {
    // set animation state
    this.setState(this.states.animating);

    // magic
    this.splitTitle();
    this.animate();
  }

  // split title in chars
  splitTitle() {
    // split in 2 parts
    const titleParts = this.dom.title.innerHTML.split('<br>');

    // clear content
    this.dom.title.innerHTML = '';

    titleParts.forEach((text, index) => {
      // wrap each node in separate span
      const span = document.createElement('span');
      span.innerHTML = text;
      span.classList.add('d-block');
      this.dom.title.append(span);

      // apply char splitting
      Splitting({ target: span });

      // add chars to array
      this.data.titleParts[index] = span.querySelectorAll('.char');
    });
  }

  // animate
  animate() {
    this.data.timeline = gsap.timeline({ onComplete: this.events.onAnimationComplete })
      // set initial values
      .set(this.dom.section, { opacity: 0 })
      .set([...this.data.titleParts], {
        y: '50%',
        scale: 0.8,
        opacity: 0,
      })
      .set(this.dom.buttons, { y: '50%', opacity: 0 })
      .set(this.dom.scrollIndicator, { y: '-25%', opacity: 0 })
      // animate in first view
      .to(this.dom.section, {
        opacity: 1,
        duration: 1,
        ease: 'power1.in',
      })
      // animate in first title
      .to(this.data.titleParts[0], {
        y: 0,
        opacity: 1,
        scale: 1,
        ease: 'power2.out',
        stagger: {
          amount: 0.35,
        },
      }, '-=0.4')
      .addLabel('nextSlide')
      // slide next slide in
      .to(this.dom.slides[1], {
        y: 0,
        duration: 1,
        ease: 'power3.inOut',
      }, '+=0.1')
      // animate next title in
      .to(this.data.titleParts[1], {
        y: 0,
        opacity: 1,
        scale: 1,
        ease: 'power2.out',
        stagger: {
          amount: 0.35,
        },
      }, '-=0.4')
      .addLabel('done')
      // animate remaining elements
      .to([this.dom.buttons, this.dom.nav, this.dom.scrollIndicator], {
        y: 0,
        opacity: 1,
        duration: 0.75,
        ease: 'power1.out',
        onComplete: () => {
          gsap.set([this.dom.buttons, this.dom.nav, this.dom.scrollIndicator], { clearProps: 'all' });
        },
      }, 'done+=0.1');
  }

  // animation has finished
  onAnimationComplete() {
    // set complete state
    this.setState(this.states.complete);

    // set session storage
    this.setSessionStorage();

    // unbind load event
    window.removeEventListener('load', this.events.onLoad);

    // remove timeline
    if (this.data.timeline) {
      this.data.timeline.kill();
      this.data.timeline = null;
    }

    // dispatch custom event
    this.dom.section.dispatchEvent(this.events.done);
  }

  // set state
  setState(state) {
    // set state
    this.data.state = state;

    // apply state
    document.documentElement.dataset.state = this.data.state;

    // dispatch event
    document.documentElement.dispatchEvent(this.customEvents.stateChange);
  }

  // get session storage
  getSessionStorage() {
    return sessionStorage.getItem(this.data.sessionStorage.key);
  }

  // set session storage
  setSessionStorage() {
    sessionStorage.setItem(this.data.sessionStorage.key, this.data.sessionStorage.value);
  }
}
