// import { gsap } from 'gsap';
// import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

// gsap.registerPlugin(ScrollToPlugin);

window.onload = () => {
  if (!document.documentElement.classList.contains('play-intro')) {
    const { hash } = window.location;
    if (hash) {
      const el = document.getElementById(hash.substring(1));
      if (el) {
        // bypass auto scrolling
        if ('scrollRestoration' in window.history) {
          window.history.scrollRestoration = 'manual';
        }

        // setTimeout to trigger reflow
        setTimeout(() => el.scrollIntoView(), 0);
        // gsap.to(window, { duration: 0, scrollTo: el, ease: 'power2.inOut' });
      }
    }
  }
};
