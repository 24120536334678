import Masonry from '@/js/components/masonry';

class Widget {
  constructor() {
    this.dom = {
      masonryTwoColumns: document.querySelectorAll('.js-masonry-two-columns'),
    };

    this.components = {
      masonryTwoColumnsInstances: [],
    };

    this.mount();
  }

  mount() {
    this.dom.masonryTwoColumns.forEach((container) => {
      this.components.masonryTwoColumnsInstances.push(new Masonry({ container }, 2));
    });
  }
}

if (document.querySelector('main.page-widget')) {
  // eslint-disable-next-line no-new
  new Widget();
}
