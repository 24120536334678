import { gsap } from 'gsap';
import { lerp } from '@/js/utils/math';

export default class Cursor {
  constructor(el) {
    this.dom = {
      el,
    };

    this.data = {
      mouse: { x: 0, y: 0 },
      bounds: this.dom.el.getBoundingClientRect(),
      renderedStyles: {
        tx: { previous: 0, current: 0, amt: 0.2 },
        ty: { previous: 0, current: 0, amt: 0.2 },
        scale: { previous: 1, current: 1, amt: 0.15 },
      },
    };

    this.events = {
      onMouseMoveOnce: this.onMouseMoveOnce.bind(this),
      onMouseEnter: this.onMouseEnter.bind(this),
      onMouseLeave: this.onMouseLeave.bind(this),
      onRender: this.onRender.bind(this),
    };

    this.mount();
  }

  mount() {
    this.dom.el.style.opacity = 0;

    window.addEventListener('mousemove', (e) => {
      this.data.mouse = {
        x: e.clientX,
        y: e.clientY,
      };
    });
    window.addEventListener('mousemove', this.events.onMouseMoveOnce);

    // Mouse effects on all links and others
    [...document.querySelectorAll('a, button, input, textarea, select, .cursor-pointer')].forEach((link) => {
      link.addEventListener('mouseenter', this.events.onMouseEnter);
      link.addEventListener('mouseleave', this.events.onMouseLeave);
    });
  }

  onMouseMoveOnce() {
    window.removeEventListener('mousemove', this.events.onMouseMoveOnce);

    this.data.renderedStyles.tx.previous = this.data.mouse.x - this.data.bounds.width / 2;
    this.data.renderedStyles.tx.current = this.data.renderedStyles.tx.previous;

    this.data.renderedStyles.ty.previous = this.data.mouse.y - this.data.bounds.height / 2;
    this.data.renderedStyles.ty.current = this.data.renderedStyles.ty.previous;

    gsap.to(this.dom.el, { duration: 0.9, ease: 'Power3.easeOut', opacity: 1 });
    this.events.onRender();
  }

  onMouseEnter() {
    this.data.renderedStyles.scale.current = 2.5;
  }

  onMouseLeave() {
    this.data.renderedStyles.scale.current = 1;
  }

  onRender() {
    this.data.renderedStyles.tx.current = this.data.mouse.x - this.data.bounds.width / 2;
    this.data.renderedStyles.ty.current = this.data.mouse.y - this.data.bounds.height / 2;

    Object.keys(this.data.renderedStyles).forEach((key) => {
      this.data.renderedStyles[key].previous = lerp(this.data.renderedStyles[key].previous, this.data.renderedStyles[key].current, this.data.renderedStyles[key].amt);
    });

    this.dom.el.style.transform = `
    translateX(${(this.data.renderedStyles.tx.previous)}px)
    translateY(${this.data.renderedStyles.ty.previous}px)
    scale(${this.data.renderedStyles.scale.previous})`;

    setTimeout(this.events.onRender, 1000 / 60);
  }
}
