import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// register gsap plugins
gsap.registerPlugin(ScrollTrigger);

export default class LetsPartnerTogether {
  constructor(section) {
    this.dom = {
      section,
      title: section.querySelector('.display-1'),
    };

    this.data = {
      bp: 576,
      isFirstRun: true,
    };

    this.mount();
  }

  mount() {
    ScrollTrigger.matchMedia({
      // revert all gsap changes
      [`(max-width: ${this.data.bp}px)`]: () => this.resetProps(),
      // apply gsap
      [`(min-width: ${this.data.bp}px)`]: () => this.animate(),
    });
  }

  animate() {
    const tl = gsap
      .timeline({
        scrollTrigger: {
          trigger: this.dom.section,
          scrub: 0.5,
          end: 'center top',
          onUpdate: () => {
            if (this.data.isFirstRun) {
              this.data.isFirstRun = false;
              tl.scrollTrigger.refresh();
            }
          },
        },
      })
      .fromTo(this.dom.title,
        { y: '-5%', ease: 'none' },
        { y: '5%', ease: 'none' },
        0);
  }

  resetProps() {
    gsap.set(this.dom.title, { clearProps: 'all' });
  }
}
