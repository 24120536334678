import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// register gsap plugins
gsap.registerPlugin(ScrollTrigger);

export default class ParallaxTwoColumns {
  constructor(container, options) {
    const defaultOptions = {
      rtl: true,
      parallaxBothColumns: true,
      bp: 576,
    };

    this.dom = {
      container,
      children: container.children,
    };

    this.props = {
      options: { ...defaultOptions, ...options },
      isFirstRun: true,
    };

    this.data = {
      timeline: null,
      oddChildren: [],
      evenChildren: [],
      offset: parseInt(window.getComputedStyle(container).marginTop, 10),
    };

    this.events = {
      resize: this.resetProps.bind(this),
    };

    // separate odd and even
    [...this.dom.children].forEach((node, index) => {
      if (index % 2 === 0) {
        this.data.evenChildren.push(node);
      } else {
        this.data.oddChildren.push(node);
      }
    });

    // remove column if animating one side
    if (!this.props.options.parallaxBothColumns) {
      if (this.props.options.rtl) {
        this.data.oddChildren = [];
      } else {
        this.data.evenChildren = [];
      }
    }

    window.addEventListener('resize', this.events.resize);
    this.mount();
  }

  mount() {
    ScrollTrigger.matchMedia({
      // revert all gsap changes
      [`(max-width: ${this.props.options.bp}px)`]: () => this.resetProps(),
      // apply gsap
      [`(min-width: ${this.props.options.bp}px)`]: () => this.animate(),
    });
  }

  animate() {
    this.data.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.dom.container,
        scrub: true,
        invalidateOnRefresh: true,
        onUpdate: () => {
          if (this.data.isFirstRun) {
            this.data.isFirstRun = false;
            this.data.timeline.scrollTrigger.refresh();
          }
        },
      },
    });

    if (this.data.oddChildren.length > 0) {
      this.data.timeline.to(this.data.oddChildren, {
        y: this.props.options.rtl ? this.data.offset : Math.abs(this.data.offset),
        ease: 'none',
      }, 0);
    }

    if (this.data.evenChildren.length > 0) {
      this.data.timeline.to(this.data.evenChildren, {
        y: this.props.options.rtl ? 0 : this.data.offset,
        ease: 'none',
      }, 0);
    }
  }

  resetProps() {
    gsap.set(this.dom.children, { clearProps: 'all' });
  }
}
