import Swiper from 'swiper/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// register gsap plugins
gsap.registerPlugin(ScrollTrigger);

export default class Instagram {
  constructor(swiper) {
    this.dom = {
      swiper,
      swiperWrapper: swiper.querySelector('.swiper-wrapper'),
    };

    this.data = {
      isFirstRun: true,
      hasUserInteracted: false,
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 10,
        freeMode: true,
        grabCursor: true,
        initialSlide: 3,
        on: {
          afterInit: () => {
            this.initScrollMove();
          },
          sliderMove: () => {
            this.data.hasUserInteracted = true;
          },
        },
      },
    };

    this.components = {
      swiper: null,
    };

    this.mount();
  }

  mount() {
    this.initSwiper();
  }

  initSwiper() {
    this.components.swiper = new Swiper(
      this.dom.swiper,
      this.data.swiperOptions,
    );
  }

  initScrollMove() {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: this.dom.swiper,
        scrub: 0.5,
        invalidateOnRefresh: true,
        onUpdate: () => {
          if (this.data.isFirstRun) {
            this.data.isFirstRun = false;
            tl.scrollTrigger.refresh();
          }

          if (this.data.hasUserInteracted) {
            tl.kill();
          }
        },
      },
    })
      .to(this.dom.swiperWrapper, {
        x: `-=${window.innerWidth * 0.15}`,
      });
  }
}
