import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { throttle } from 'lodash-es';

// register gsap plugins
gsap.registerPlugin(ScrollTrigger);

export default class PinnedListWithImages {
  constructor(services) {
    this.dom = {
      services,
      colImage: services.querySelector('.col-images'),
      liImages: services.querySelectorAll('.li-img'),
    };

    this.data = {
      bp: 576,
    };

    this.events = {
      throttledGetCurrentSection: throttle(this.getCurrentSection, 150).bind(this),
    };

    this.mount();
  }

  mount() {
    ScrollTrigger.matchMedia({
      // revert all gsap changes
      [`(max-width: ${this.data.bp}px)`]: () => this.resetProps(),
      // apply gsap
      [`(min-width: ${this.data.bp}px)`]: () => this.addAnimation(),
    });
  }

  addAnimation() {
    // pin section
    gsap.timeline({
      scrollTrigger: {
        trigger: this.dom.services,
        pin: this.dom.services,
        scrub: 0,
        start: 'top top',
        end: () => `+=${this.getServiceOffset()}`,
        onUpdate: this.events.throttledGetCurrentSection,
        anticipatePin: 1,
        invalidateOnRefresh: true,
      },
    })
      // allow image scroll
      .to(this.dom.colImage, {
        y: () => -this.getServiceOffset(), duration: 1, ease: 'none',
      }, 0);

    // animate each image
    this.dom.liImages.forEach((li, index) => {
      if (index > 0) {
        li.classList.add('inactive');
      }

      gsap.timeline({
        scrollTrigger: {
          trigger: li,
          scrub: 0,
          start: 'top bottom',
          end: 'bottom center',
        },
      })
        .from(li, {
          rotation: 0, x: 0, y: '15%', duration: 0.15, ease: 'power1.out',
        }, 0);
    });
  }

  resetProps() {
    // undo prop changes
    gsap.set(this.dom.colImage, { clearProps: 'all' });
    gsap.set(this.dom.liImages, { clearProps: 'all' });
  }

  getServiceOffset() {
    return this.dom.colImage.offsetHeight - window.innerHeight;
  }

  getCurrentSection() {
    const activeItems = [];
    const scrollYPos = window.scrollY - (window.innerHeight * 0.4);

    // Find the current section
    this.dom.liImages.forEach((el) => {
      const li = el;
      const service = document.getElementById(li.dataset.id);
      const scrollOffset = scrollYPos - li.offsetHeight;

      // remove all active states
      service.classList.remove('active');
      li.classList.add('inactive');

      // if we haven't scrolled passed the li, add it to the activeItems list
      if (scrollOffset < li.offsetTop && activeItems.length === 0) {
        activeItems.push({ li, service });
      }
    });

    // set first active item as active
    if (activeItems.length > 0) {
      const firstActive = activeItems[0];
      firstActive.li.classList.remove('inactive');
      firstActive.service.classList.add('active');
    }
  }
}
