import Noise from '@/js/components/noise';

class PageError {
  constructor() {
    this.dom = {
      noiseCanvas: document.getElementById('noise'),
    };

    this.components = {
      noise: null,
    };

    this.mount();
  }

  mount() {
    if (this.dom.noiseCanvas) {
      this.components.noise = new Noise(this.dom.noiseCanvas);
    }
  }
}

if (document.getElementById('page-error')) {
  // eslint-disable-next-line no-new
  new PageError();
}
