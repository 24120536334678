import VanillaTilt from 'vanilla-tilt';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// components
import ParallaxTwoColumns from '@/js/components/animations/parallax_two_columns';

// register gsap plugins
gsap.registerPlugin(ScrollTrigger);

export default class Steps {
  constructor(section) {
    this.dom = {
      section,
      rowSteps: section.querySelector('.row-steps'),
      cards: section.querySelectorAll('.c-card-step'),
    };

    this.data = {
      rowStepsBp: 768,
      parallaxBothColumns: false,
    };

    this.components = {
      rowParallax: null,
    };

    this.mount();
  }

  mount() {
    if (this.dom.cards.length > 0) {
      this.cardsTiltHoverAnimation();
    }

    if (this.dom.rowSteps) {
      this.stepsParallax();
    }
  }

  // steps tilt hover
  cardsTiltHoverAnimation() {
    VanillaTilt.init(this.dom.cards, { max: 6, scale: 1.025 });
  }

  // parallax
  stepsParallax() {
    this.components.rowParallax = new ParallaxTwoColumns(
      this.dom.rowSteps,
      {
        bp: this.data.rowStepsBp,
        parallaxBothColumns: this.data.parallaxBothColumns,
      },
    );
  }
}
