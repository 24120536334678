import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Splitting from 'splitting';

// register gsap plugins
gsap.registerPlugin(ScrollTrigger);

export default class CaseItem {
  constructor(el) {
    this.dom = {
      el,
      imgWrapper: el.querySelector('.img-wrapper'),
      img: el.querySelector('img'),
      title: el.querySelector('.title'),
      listTags: el.querySelector('.list-tags'),
      titleChars: null,
    };

    this.data = {
      bp: 576,
    };

    this.events = {
      onMouseEnter: this.onMouseEnter.bind(this),
      onMouseLeave: this.onMouseLeave.bind(this),
    };

    this.mount();
  }

  mount() {
    // apply char splitting
    Splitting({ target: this.dom.title });

    // add chars to array
    this.dom.titleChars = this.dom.title.querySelectorAll('.char');

    this.animateTextIn();
    this.textParallax();

    this.dom.imgWrapper.addEventListener('mouseenter', this.events.onMouseEnter);
    this.dom.imgWrapper.addEventListener('mouseleave', this.events.onMouseLeave);
  }

  animateTextIn() {
    // set starting pos
    gsap.set(this.dom.titleChars, {
      y: '33%',
      opacity: 0,
    });

    // animate
    gsap.timeline({
      scrollTrigger: {
        trigger: this.dom.title,
        scrub: false,
        onEnter: () => {
          gsap.timeline()
            .to(this.dom.titleChars, {
              y: '0%',
              opacity: 1,
              stagger: {
                amount: 0.4,
                ease: 'none',
              },
            });
        },
      },
    });
  }

  textParallax() {
    gsap.timeline({
      defaults: { ease: 'none' },
      scrollTrigger: {
        trigger: this.dom.el,
        scrub: true,
      },
    })
      .fromTo(this.dom.title,
        { y: '80%' },
        { y: '-80%' });
  }

  onMouseEnter() {
    ScrollTrigger.matchMedia({
      // revert some gsap changes
      [`(max-width: ${this.data.bp}px)`]: () => this.resetProps(),
      // apply gsap
      [`(min-width: ${this.data.bp}px)`]: () => {
        gsap
          // set defaults
          .timeline({ defaults: { duration: 1, ease: 'expo' } })

          // animate
          .to(this.dom.imgWrapper, { scale: 0.95 })
          .to(this.dom.img, { scale: 1.2, opacity: 1 }, 0)
          .to(this.dom.titleChars, {
            letterSpacing: '0.05em',
            stagger: { from: 'center' },
          }, 0)
          .to(this.dom.listTags, { y: 0, opacity: 1 }, 0);
      },
    });
  }

  onMouseLeave() {
    ScrollTrigger.matchMedia({
      [`(max-width: ${this.data.bp}px)`]: () => this.resetProps(),
      // apply gsap
      [`(min-width: ${this.data.bp}px)`]: () => {
        gsap
          // set defaults
          .timeline({ defaults: { duration: 1, ease: 'expo' } })

          // animate
          .to([this.dom.imgWrapper, this.dom.img], { scale: 1 })
          .to(this.dom.img, { opacity: 0.7 }, 0)
          .to(this.dom.titleChars, { letterSpacing: 0 }, 0)
          .to(this.dom.listTags, { y: '100%', opacity: 0 }, 0);
      },
    });
  }

  resetProps() {
    gsap.set([this.dom.titleChars, this.dom.listTags], { clearProps: 'all' });
  }
}
