// vendor
import Swiper, { EffectFade, Lazy, Autoplay } from 'swiper/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// components
import Validation from '@/js/components/forms/validation';
import PinnedListWithImages from '@/js/components/animations/pinned_list_with_images';
import Intro from './partials/intro';
import Steps from './partials/steps';
import Cases from './partials/cases';
import LetsPartnerTogether from './partials/lets_partner_together';
import News from './partials/news';
import Instagram from './partials/instagram';

// configure Swiper to use modules
Swiper.use([EffectFade, Lazy, Autoplay]);

// register gsap plugins
gsap.registerPlugin(ScrollTrigger);

class Home {
  constructor() {
    this.dom = {
      swiperIntro: document.getElementById('swiper-intro'),
      formSubscribe: document.querySelector('.js-form-subscribe'),
      services: document.getElementById('services'),
      steps: document.getElementById('steps'),
      cases: document.getElementById('cases'),
      letsPartnerTogether: document.getElementById('lets-partner-together'),
      news: document.getElementById('news'),
      swiperInstagram: document.getElementById('swiper-instagram'),
    };

    this.components = {
      swiperIntroInstance: null,
      subscribeFormValidation: null,
      intro: null,
      services: null,
      steps: null,
      cases: null,
      letsPartnerTogether: null,
      news: null,
      instagram: null,
    };

    this.data = {
      swiperIntroOptions: {
        loop: true,
        initialSlide: 0,
        speed: 1500,
        autoplay: {
          delay: 3500,
        },
        // pagination: {
        //   el: '.swiper-pagination',
        //   dynamicBullets: true,
        //   clickable: true,
        // },
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev',
        // },
        effect: 'fade',
        on: {
          init() {
            document.documentElement.classList.remove('play-intro');
          },
        },
      },
    };

    this.events = {
      onIntroDone: this.onIntroDone.bind(this),
    };

    this.mount();
  }

  mount() {
    if (this.dom.swiperIntro && document.documentElement.classList.contains('play-intro')) {
      this.components.intro = new Intro(this.dom.swiperIntro);
      this.dom.swiperIntro.addEventListener('done', this.events.onIntroDone);
    } else {
      this.onIntroDone();
    }
  }

  onIntroDone() {
    /// /////////////////// ///
    /// SEQUENCING MATTERS! ///
    /// /////////////////// ///

    if (this.dom.swiperIntro) {
      // unbind done event
      this.dom.swiperIntro.removeEventListener('done', this.events.onIntroDone);

      // if initialized after animation, start from second slide
      if (document.documentElement.dataset.state) {
        this.data.swiperIntroOptions.initialSlide = 1;
      }

      // init intro swiper
      this.components.swiperIntroInstance = new Swiper(
        this.dom.swiperIntro,
        this.data.swiperIntroOptions,
      );
    }

    // validate subscribe form
    if (this.dom.formSubscribe) {
      this.components.subscribeFormValidation = new Validation(this.dom.formSubscribe);
    }

    // services actions
    if (this.dom.services) {
      this.components.services = new PinnedListWithImages(this.dom.services);
    }

    // steps actions
    if (this.dom.steps) {
      this.components.steps = new Steps(this.dom.steps);
    }

    // cases actions
    if (this.dom.cases) {
      this.components.cases = new Cases(this.dom.cases);
    }

    // cta actions
    if (this.dom.letsPartnerTogether) {
      this.components.letsPartnerTogether = new LetsPartnerTogether(this.dom.letsPartnerTogether);
    }

    // news actions
    if (this.dom.news) {
      this.components.news = new News(this.dom.news);
    }

    // init instagram swiper
    if (this.dom.swiperInstagram) {
      this.components.instagram = new Instagram(this.dom.swiperInstagram);
    }
  }
}

if (document.getElementById('page-home')) {
  // eslint-disable-next-line no-new
  new Home();
}
