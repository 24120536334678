import fitty from 'fitty';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// components
import ParallaxTwoColumns from '@/js/components/animations/parallax_two_columns';

// register gsap plugins
gsap.registerPlugin(ScrollTrigger);

export default class Cases {
  constructor(section) {
    this.dom = {
      section,
      title: section.querySelector('.js-news-title'),
      newsParallax: section.querySelector('.row-news'),
    };

    this.data = {
      newsParallaxBp: 768,
      parallaxBothColumns: false,
      isFirstRun: true,
      rtl: false,
      titleFitTextSizes: {
        min: 72,
        max: 250,
      },
    };

    this.components = {
      newsParallax: null,
    };

    this.events = {
      fit: this.titleAnimation.bind(this),
    };

    this.mount();
  }

  mount() {
    if (this.dom.title) {
      this.titleFitText();
      // fired when the element has been fitted to the parent container
      this.dom.title.addEventListener('fit', this.events.fit);
    }

    if (this.dom.newsParallax) {
      this.newsParallax();
    }
  }

  // fit text to container
  titleFitText() {
    fitty(this.dom.title, {
      minSize: this.data.titleFitTextSizes.min,
      maxSize: this.data.titleFitTextSizes.max,
    });
  }

  // title animation
  titleAnimation() {
    const titleTimeline = gsap
      .timeline({
        scrollTrigger: {
          trigger: this.dom.title,
          scrub: true,
          start: '125% bottom',
          onUpdate: () => {
            if (this.data.isFirstRun) {
              this.data.isFirstRun = false;
              titleTimeline.scrollTrigger.refresh();
            }
          },
        },
      })
      .set(this.dom.title, { clearProps: 'y, opacity' })
      .to(this.dom.title,
        {
          y: () => this.dom.section.offsetHeight * 0.5,
          opacity: 0,
          ease: 'sine.in',
        },
        0);
  }

  // parallax news
  newsParallax() {
    this.components.newsParallax = new ParallaxTwoColumns(
      this.dom.newsParallax, {
        bp: this.data.newsParallaxBp,
        parallaxBothColumns: this.data.parallaxBothColumns,
        rtl: this.data.rtl,
      },
    );
  }
}
