// vendor
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

// components
import Validation from '@/js/components/forms/validation';
import GoogleMaps from '@/js/components/google_maps/map';

gsap.registerPlugin(ScrollToPlugin);

class Contact {
  constructor() {
    this.dom = {
      mapEl: document.getElementById('map'),
      formContact: document.getElementById('form-contact'),
      alertScroll: document.querySelector('.js-alert-scroll:not(.d-none)'),
    };

    this.props = {
      mapMarkers: null,
      mapOptions: null,
      infoWindowContent: null,
    };

    this.components = {
      googleMaps: null,
      contactFormValidation: null,
    };

    this.events = {
      onMapLoaded: this.onMapLoaded.bind(this),
    };

    this.mount();
  }

  mount() {
    if (this.dom.mapEl) {
      this.initMaps();
    }

    if (this.dom.formContact) {
      this.components.contactFormValidation = new Validation(this.dom.formContact, true);
      // this.dom.formContact.addEventListener('submit', this.submitForm.bind(this));
    }

    if (this.dom.alertScroll) {
      gsap.to(window, { duration: 0.5, scrollTo: this.dom.alertScroll, ease: 'power1' });
    }
  }

  initMaps() {
    // parse props
    this.props.mapOptions = JSON.parse(this.dom.mapEl.dataset.options);
    this.props.mapMarkers = JSON.parse(this.dom.mapEl.dataset.markers);

    // listen to map loaded
    this.dom.mapEl.addEventListener('loaded', this.events.onMapLoaded);

    // create map instance
    this.components.googleMaps = new GoogleMaps(this.dom.mapEl, this.props.mapOptions);
  }

  onMapLoaded() {
    // add markers
    this.props.mapMarkers.forEach((marker) => {
      this.components.googleMaps.addMarker(marker);
    });
  }

  /* eslint-disable class-methods-use-this */
  trackSuccessfullLead() {
    console.log('trackSuccessfullLead');
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'conversion', { send_to: 'AW-299216993/HIV6CMLUh_UCEOHg1o4B' });
    }
  }
}

if (document.getElementById('page-contact')) {
  // eslint-disable-next-line no-new
  new Contact();
}
